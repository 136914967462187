<script setup>
import {Link, router} from '@inertiajs/vue3';
import AppHead from "@/Components/AppHead.vue";
import DarkModeSwitch from "@/Components/DarkModeSwitch.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {onMounted, ref, watch} from "vue";
import {gsap} from "gsap";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import SectionBorder from "@/Components/SectionBorder.vue";

defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
});

const classesRotate = ["-tw-rotate-6", "tw-rotate-6", "tw-rotate-[4deg]", "-tw-rotate-[4deg]"]
const classesShadowColor = ["dark:tw-shadow-zinc-300",
    "dark:tw-shadow-amber-300",
    "dark:tw-shadow-orange-300",
    "dark:tw-shadow-lime-300",
    "dark:tw-shadow-teal-300",
    "dark:tw-shadow-teal-300",
    "dark:tw-shadow-amber-300",
    "dark:tw-shadow-orange-300",
    "dark:tw-shadow-lime-300",
    "dark:tw-shadow-teal-300"]

function selectRandom(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

function randomNum(min, max) {
    return Math.floor(Math.random() * (max - min)) + min; // You can remove the Math.floor if you don't want it to be an integer
}

const showcaseLoaderVisible = ref(false);
let timelines = [];
onMounted(() => {
    // create gsap context
        let animation = gsap.utils.toArray('.animate-opacity');

        animation.forEach((el, _i) => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: '3% bottom'
                }
            });

            timelines.push(tl);

            // noinspection JSCheckFunctionSignatures
            tl.from(el, {
                opacity: 0,
                duration: 1.7,
            });
        })
        gsap.from('.image-showcase', {
            scrollTrigger: {
                trigger: '.image-showcase',
                start: '5% bottom'
            },
            opacity: 0,
            duration: 0.48,
            stagger: 0.18,
            onStart: () => {
                showcaseLoaderVisible.value = true
            },
            onComplete: () => {
                showcaseLoaderVisible.value = false
                images_shown.value = true;
            }
        });
        gsap.from('.features-card', {
            scrollTrigger: {
                trigger: '.features-card',
                start: '3% bottom'
            },
            opacity: 0,
            duration: 0.48 * 2,
            stagger: 0.28,
            ease: "power1",
        });
});

const featuresPlayful = ref(false);
const featuresTextPlayful = "Just sign up already, and try the amazing features we have😆😆!";

const animatingFeatures = ref(false);
const animatingProgress = ref(0);
const circular_progress = ref({
    opacity: 0,
});
const cachedResponse = ref(false);
const randomBoolean = () => Math.random() >= 0.7;

const features_progress = ref(0);
const images_shown = ref(false);

function animateLoading(seconds = 4.99) {
    gsap.fromTo(circular_progress.value, {
        opacity: 0,
    }, {
        opacity: 1,
        duration: seconds,
        delay: 0.001,
        onStart: () => {
            animatingFeatures.value = true;
        },
        onUpdate: () => {
            randomBoolean() ?
                features_progress.value = circular_progress.value.opacity * 100
                :
                null;
        },
        onComplete: () => {
            features_progress.value = 0;
            cachedResponse.value = true;
        }
    });
}

watch(featuresPlayful, (value, _old) => {
    const secondsLoading = !cachedResponse.value ? randomNum(2, 5) : 0.01;
    animateLoading(secondsLoading);
    gsap.from(".features-text", {
        opacity: 0,
        delay: secondsLoading,
        duration: secondsLoading - 2.1,
        ease: "expo.out",
        onUpdate: (progress) => {
            animatingProgress.value = progress;
        },
        onComplete: () => {
            animatingFeatures.value = false;
        }
    });
    gsap.to(window,
        {
            scrollTo: "#features",
            duration: 0.25,
        });
    });

</script>

<template>
    <AppHead title="Welcome"/>
    <div
        class="tw-flex tw-flex-col tw-min-h-screen
        tw-bg-center selection:tw-bg-red-500 selection:tw-text-white tw-overflow-x-clip
        tw-bg-none tw-bg-slate-200
        dark:tw-from-[#1C092C] dark:tw-to-[#3C0568] dark:tw-bg-gradient-to-tr">
        <div v-if="canLogin" class="sm:tw-fixed sm:tw-top-0 sm:tw-right-0 tw-p-6 tw-text-right tw-z-10">
            <DarkModeSwitch class="tw-mx-4"/>
            <Link v-if="$page.props.auth.user" :href="route('dashboard')"
                  class="tw-font-semibold tw-text-gray-600 hover:tw-text-gray-900 dark:tw-text-gray-400 dark:hover:tw-text-white focus:outline focus:outline-2 focus:tw-rounded-sm focus:outline-red-500">
                Dashboard
            </Link>

            <template v-else>
                <Link :href="route('login')"
                      class="tw-font-semibold tw-text-gray-600 hover:tw-text-gray-900 dark:tw-text-gray-400
                      dark:hover:tw-text-white focus:outline focus:outline-2 focus:tw-rounded-sm focus:outline-red-500">
                    Log in
                </Link>

                <Link v-if="canRegister" :href="route('register')"
                      class="tw-ml-4 tw-font-semibold tw-text-gray-600 hover:tw-text-gray-900 dark:tw-text-gray-400 dark:hover:tw-text-white focus:outline focus:outline-2 focus:tw-rounded-sm focus:outline-red-500">
                    Register
                </Link>
            </template>
        </div>
        <div id="heading"
             class="tw-relative sm:tw-flex sm:tw-justify-center sm:tw-items-center
                tw-mx-2 -tw-my-12 md:tw-my-10 lg:tw-my-24 tw-text-black dark:tw-text-white tw-font-[roboto]">
            <div
                class="tw-flex tw-flex-col tw-bg-contain tw-bg-no-repeat landing-heading-bg tw-bg-center tw-p-60 tw-text-center tw-space-y-3
                    tw-px-0 tw-grow">
                <h1
                    class="tw-text-slate-900 tw-font-normal tw-text-5xl sm:tw-text-6xl lg:tw-text-8xl -tw-tracking-[0.414px] dark:tw-text-white">
                    UnsparkAI
                </h1>
                <span
                    class="absolute tw-text-black tw-font-normal tw-text-lg sm:tw-text-xl lg:tw-text-2xl -tw-tracking-[0.414px]
                    tw-text-prose dark:tw-hidden tw-inset-x-0 tw-top-14">
                    Well, sorry for the <span class="tw-text-xs">not really great</span> light theme. dark theme is
                    better.
                    right? 😉
                </span>
                <span
                    class="tw-text-slate-900 tw-font-normal tw-text-lg sm:tw-text-xl lg:tw-text-3xl -tw-tracking-[0.414px] dark:tw-text-white
                    tw-text-prose">
                    Unleash Your Creativity
                </span>
                <primary-button
                    class="cta-btn !tw-text-black tw-font-semibold tw-text-lg tw-py-4 tw-px-6 tw-rounded-full
                    tw-shadow-lg tw-border-2 tw-border-[#D0BCFF] tw-border-opacity-50 tw-border-solid tw-mx-auto tw-w-[fit-content]
                    tw-bg-violet-200 dark:tw-bg-violet-400 dark:tw-border-[#3C0568] tw-brightness-[0.86] dark:tw-brightness-100
                    animate__animated animate__heartBeat animate__repeat-2 animate__delay-2s animate__slower"
                    @click.once="router.visit(route('login'))">

                    <span class="material-symbols-rounded">
                        arrow_outward
                    </span>
                    Experience Now
                </primary-button>
            </div>
        </div>
        <section-border/>
        <section id="features" class="py-12">
            <div class="tw-relative tw-flex tw-flex-col md:tw-flex-none tw-justify-center sm:tw-items-center
            tw-mx-2 tw-my-28 tw-text-black dark:tw-text-white tw-font-[roboto]
            tw-border-4 tw-border-[#F2F2F2C4] tw-rounded-xl tw-p-4 animate-opacity
            tw-bg-white dark:tw-bg-[#1C092C] dark:tw-border-[#3C0568]
            dark:tw-border-opacity-50 dark:tw-border-solid
                dark:tw-shadow-lg dark:tw-shadow-[#3C0568] tw-space-y-2">
                <q-circular-progress
                    rounded
                    :value="features_progress"
                    size="70px"
                    :thickness="0.2"
                    color="blue"
                    center-color="grey-8"
                    track-color="transparent"
                    class="q-ma-md"
                    v-if="animatingFeatures"
                />
                <span v-if="featuresPlayful" class="tw-text-lg features-text">
                        ~Summarized:
                    </span>
                <div
                    class="tw-font-[helvetica] features-text tw-text-pretty tw-px-0 md:tw-px-16 lg:tw-px-20 tw-text-lg">
                    <template v-if="featuresPlayful">
                        {{ featuresTextPlayful }}
                        <primary-button
                            class="cta-btn !tw-text-black tw-font-semibold tw-text-lg tw-py-4 tw-px-6 tw-rounded-full
                    tw-shadow-lg tw-border-2 tw-border-[#D0BCFF] tw-border-opacity-50 tw-border-solid tw-mx-auto tw-w-[fit-content]
                    tw-bg-violet-200 dark:tw-bg-violet-400 dark:tw-border-[#3C0568]"
                            @click.once="router.visit(route('login'))">

                    <span class="material-symbols-rounded">
                        arrow_outward
                    </span>
                            Experience Now
                        </primary-button>
                    </template>
                    <template v-else>
                        <div class="container tw-mx-auto tw-p-4 tw-mb-14">
                            <h3 class="tw-text-3xl tw-font-bold tw-text-center tw-mb-8">UnsparkAI Features: Ignite Your
                                Creativity</h3>

                            <div
                                class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-8 lg:tw-min-h-svh">
                                <!-- Feature 1 -->
                                <div
                                    class="feature-item tw-bg-white dark:tw-bg-inherit dark:tw-shadow-current features-card
                                    dark:tw-ring-4 dark:tw-ring-[#3C0568] tw-drop-shadow-md
                                     tw-shadow-xl tw-rounded tw-p-4 tw-hidden">
                                    <h2 class="tw-text-xl tw-font-semibold tw-mb-2">AI-Powered Text Processing</h2>
                                    <p>Enhance your writing with our advanced AI text processing tools. Grammar checks,
                                        style suggestions, and content generation are all at your fingertips.</p>
                                </div>

                                <!-- Feature 2 -->
                                <div class="feature-item tw-bg-white dark:tw-bg-inherit dark:tw-shadow-current
                                    dark:tw-ring-4 dark:tw-ring-[#3C0568] tw-drop-shadow-md
                                     tw-shadow-xl tw-rounded tw-p-4">
                                    <h2 class="tw-text-xl tw-font-semibold tw-mb-2">Dynamic Image Generation</h2>
                                    <p>Unleash your creativity with UnsparkAI’s cutting-edge AI technology. Simply
                                        describe your vision, and our platform will generate stunning, one-of-a-kind
                                        images that capture your ideas perfectly. Ideal for designers, marketers, and
                                        content creators seeking to stand out.</p>
                                </div>
                                <!-- Feature 3 -->
                                <div class="feature-item tw-bg-white dark:tw-bg-inherit dark:tw-shadow-current
                                    dark:tw-ring-4 dark:tw-ring-[#3C0568] tw-drop-shadow-md
                                     tw-shadow-xl tw-rounded tw-p-4 tw-hidden">
                                    <h2 class="tw-text-xl tw-font-semibold tw-mb-2">Audio AI Processing</h2>
                                    <p>Improve your audio content with our cutting-edge AI processing. Noise reduction,
                                        sound enhancement, and music creation are all made easy.</p>
                                </div>

                                <!-- Feature 4 -->
                                <div class="feature-item tw-bg-white dark:tw-bg-inherit dark:tw-shadow-current features-card
                                    dark:tw-ring-4 dark:tw-ring-[#3C0568] tw-drop-shadow-md
                                     tw-shadow-xl tw-rounded tw-p-4">
                                    <h2 class="tw-text-xl tw-font-semibold tw-mb-2">Private Collaborative Workspace</h2>
                                    <p>Join or create a private workspace with your team of creatives. Share projects,
                                        get feedback, and grow your own creative network.</p>
                                </div>

                                <!-- Feature 5 -->
                                <div class="feature-item tw-bg-white dark:tw-bg-inherit dark:tw-shadow-current features-card
                                    dark:tw-ring-4 dark:tw-ring-[#3C0568] tw-drop-shadow-md
                                     tw-shadow-xl tw-rounded tw-p-4">
                                    <h2 class="tw-text-xl tw-font-semibold tw-mb-2">Educational Resources</h2>
                                    <p>Unleash your creativity with UnsparkAI’s cutting-edge AI technology. Simply
                                        describe your vision, and our platform will generate stunning, one-of-a-kind
                                        images that capture your ideas perfectly. Ideal for designers, marketers, and
                                        content creators seeking to stand out.</p>
                                </div>

                                <!-- Feature 6 -->
                                <div class="feature-item tw-bg-white dark:tw-bg-inherit dark:tw-shadow-current features-card
                                    dark:tw-ring-4 dark:tw-ring-[#3C0568] tw-drop-shadow-md
                                     tw-shadow-xl tw-rounded tw-p-4 tw-hidden">
                                    <h2 class="tw-text-xl tw-font-semibold tw-mb-2">Customizable User Experience</h2>
                                    <p>Experience a platform that adapts to you. UnsparkAI offers a highly customizable
                                        interface, allowing you to personalize your creative journey. Our intuitive
                                        design ensures that your workflow is smooth, efficient, and tailored to your
                                        unique needs.
                                    </p>
                                </div>

                                <div class="feature-item tw-bg-white dark:tw-bg-inherit dark:tw-shadow-current features-card
                                    dark:tw-ring-4 dark:tw-ring-[#3C0568] tw-drop-shadow-md
                                     tw-shadow-xl tw-rounded tw-p-4">
                                    <h2 class="tw-text-xl tw-font-semibold tw-mb-2">Open Community</h2>
                                    <p>
                                        Be inspired by the incredible works of our community. The UnsparkAI showcase
                                        features a diverse collection of creations, each with a story to tell. Join us
                                        and contribute to a growing gallery of imagination and artistry.
                                    </p>
                                </div>

                                <!-- Feature 7 -->
                                <div class="feature-item tw-bg-white dark:tw-bg-inherit dark:tw-shadow-current
                                    dark:tw-ring-4 dark:tw-ring-[#3C0568] tw-drop-shadow-md
                                     tw-shadow-xl tw-rounded tw-p-4 tw-hidden">
                                    <h2 class="tw-text-xl tw-font-semibold tw-mb-2">Cross-Platform Integration</h2>
                                    <p>Create on any device with our cross-platform integration. Your projects are
                                        accessible wherever you go, on any device.</p>
                                </div>
                            </div>
                        </div>

                    </template>
                </div>
                <secondary-button class="tw-text-center md:!tw-p-2 tw-w-fit  tw-shadow-xl tw-shadow-amber-400"
                                  @click="featuresPlayful = !featuresPlayful"
                                  :disabled="animatingFeatures||featuresPlayful">
                    Summarize features
                </secondary-button>
            </div>
        </section>
        <section-border/>
        <!--googleoff: all-->
        <section id="showcase" class="py-12" data-nosnippet>
            <div class="tw-relative tw-flex tw-flex-col md:tw-flex-none tw-justify-center sm:tw-items-center
            tw-mx-2 tw-my-28 tw-text-black dark:tw-text-white tw-font-[roboto]
            tw-border-4 tw-border-[#F2F2F2C4] tw-rounded-xl tw-p-4
            tw-bg-white dark:tw-bg-[#1C092C] dark:tw-border-[#3C0568]
            dark:tw-border-opacity-50 dark:tw-border-solid tw-overflow-auto
                dark:tw-shadow-lg dark:tw-shadow-[#3C0568] tw-space-y-2">
                <q-inner-loading :showing="showcaseLoaderVisible"
                                 label="Loading Images"/>
                <div class="container tw-mx-auto tw-p-8 tw-subpixel-antialiased">
                    <div class="tw-text-3xl tw-font-semibold tw-text-center">Showcase</div>
                    <p class="tw-text-lg tw-mt-4 tw-text-center">Check out some of the amazing creations created by our
                        community
                        members.</p>
                    <p v-if="!images_shown"
                        class="tw-text-base tw-text-center">Image not showing? <span
                        class="tw-underline tw-decoration-4 tw-decoration-cyan-300 tw-font-bold">
                        scroll down
                         <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                               Scroll to the bottom to load images.
                         </q-tooltip>
                    </span>
                    </p>
                    <p class="tw-text-lg tw-mt-4 tw-text-center tw-bg-sky-100 tw-text-stone-800">
                        These are only demo Images
                        <span class="tw-underline tw-decoration-sky-400 tw-decoration-4 tw-text-3xl
                                tw-transition-all tw-duration-500 hover:tw-text-4xl tw-cursor-pointer tw-font-bold"
                              @click="router.visit(route('image-submissions'))">
                            ??
                            <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                                We present these images as examples of stock photography;
                                they are other's original works.
                               <span
                                   class="tw-underline tw-font-bold">
                                   Participate in showcasing your creative work.
                               </span>
                            </q-tooltip>
                        </span></p>
                    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-5
                    tw-gap-20 tw-mt-8 tw-px-8 tw-py-7 sm:tw-px-0 sm:tw-py-4 tw-text-center md:tw-text-left tw-font-semibold
                    tw-rounded-xl tw-p-4 animate-opacity">
                        <div class="tw-bg-white dark:tw-bg-inherit tw-cursor-pointer
                        tw-drop-shadow-md image-showcase
                         tw-shadow-xl tw-rounded tw-p-4"
                             :class="selectRandom(classesRotate).concat(' ').concat(selectRandom(classesShadowColor))">
                            <div class="tw-text-xl tw-font-semibold tw-mb-2">Creation Title</div>
                            <p>By: Artist</p>
                            <img class="tw-bg-auto tw-bg-no-repeat tw-bg-center"
                                 src="https://picsum.photos/512/768?random=1"
                                 alt="placeholder creation image">
                            <q-tooltip class="bg-red text-h6" anchor="center middle">
                                No link yet
                            </q-tooltip>
                        </div>
                        <div class="tw-bg-white dark:tw-bg-inherit tw-cursor-pointer
                        tw-drop-shadow-md image-showcase
                         tw-shadow-xl tw-rounded tw-p-4"
                             :class="selectRandom(classesRotate).concat(' ').concat(selectRandom(classesShadowColor))">
                            <div class="tw-text-xl tw-font-semibold tw-mb-2">Creation Title</div>
                            <p>By: Artist</p>
                            <img class="tw-bg-auto tw-bg-no-repeat tw-bg-center"
                                 src="https://picsum.photos/512?random=1"
                                 alt="placeholder creation image">
                            <q-tooltip class="bg-red text-h6" anchor="center middle">
                                No link yet
                            </q-tooltip>
                        </div>
                        <div class="tw-bg-white dark:tw-bg-inherit tw-cursor-pointer
                        tw-drop-shadow-md image-showcase
                         tw-shadow-xl tw-rounded tw-p-4"
                             :class="selectRandom(classesRotate).concat(' ').concat(selectRandom(classesShadowColor))">
                            <div class="tw-text-xl tw-font-semibold tw-mb-2">Creation Title</div>
                            <p>By: Artist</p>
                            <img class="tw-bg-auto tw-bg-no-repeat tw-bg-center"
                                 src="https://picsum.photos/768/512?random=1"
                                 alt="placeholder creation image">
                            <q-tooltip class="bg-red text-h6" anchor="center middle">
                                No link yet
                            </q-tooltip>
                        </div>
                        <div class="tw-bg-white dark:tw-bg-inherit tw-cursor-pointer
                        tw-drop-shadow-md image-showcase
                         tw-shadow-xl tw-rounded tw-p-4"
                             :class="selectRandom(classesRotate).concat(' ').concat(selectRandom(classesShadowColor))">
                            <div class="tw-text-xl tw-font-semibold tw-mb-2">Creation Title</div>
                            <p>By: Artist</p>
                            <img class="tw-bg-auto tw-bg-no-repeat tw-bg-center"
                                 src="https://picsum.photos/768/768?random=1"
                                 alt="placeholder creation image">
                            <q-tooltip class="bg-red text-h6" anchor="center middle">
                                No link yet
                            </q-tooltip>
                        </div>
                        <div class="tw-bg-white dark:tw-bg-inherit tw-cursor-pointer
                        tw-drop-shadow-md image-showcase
                         tw-shadow-xl tw-rounded tw-p-4"
                             :class="selectRandom(classesRotate).concat(' ').concat(selectRandom(classesShadowColor))">
                            <div class="tw-text-xl tw-font-semibold tw-mb-2">Creation Title</div>
                            <p>By: Artist</p>
                            <img class="tw-bg-auto tw-bg-no-repeat tw-bg-center"
                                 src="https://picsum.photos/1024/512?random=1"
                                 alt="placeholder creation image">
                            <q-tooltip class="bg-red text-h6" anchor="center middle">
                                No link yet
                            </q-tooltip>
                        </div>
                        <div class="tw-bg-white dark:tw-bg-inherit tw-cursor-pointer
                        tw-drop-shadow-md image-showcase
                         tw-shadow-xl tw-rounded tw-p-4"
                             :class="selectRandom(classesRotate).concat(' ').concat(selectRandom(classesShadowColor))">
                            <div class="tw-text-xl tw-font-semibold tw-mb-2">Creation Title</div>
                            <p>By: Artist</p>
                            <img class="tw-bg-auto tw-bg-no-repeat tw-bg-center"
                                 src="https://picsum.photos/768/1024?random=1"
                                 alt="placeholder creation image">
                            <q-tooltip class="bg-red text-h6" anchor="center middle">
                                No link yet
                            </q-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--googleon: all-->
    </div>
    <div id="copyright-mark">
        <div class="tw-flex tw-justify-center tw-items-center tw-p-4 tw-bg-black tw-text-white tw-text-center">
            <span class="tw-text-lg tw-font-semibold">© {{ new Date().getFullYear() }} UnsparkAI. All rights
                reserved.</span>
            <section-border/>
        </div>
    </div>
</template>

<style scoped>

.landing-heading-bg {
    background-image: url('@/../assets/BGSVG.svg');
}

.cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    align-self: stretch;
    border-radius: 172px;
}

.material-symbols-rounded {
    font-variation-settings: 'FILL' 1,
    'wght' 700,
    'GRAD' 200,
    'opsz' 40
}
</style>
